<script>
  import { ToastNotification } from 'carbon-components-svelte'
  import { toast } from 'react-toastify'
  import { state } from '../../store/state'

  const lastNotification = state.lastNotification

  let current
</script>

{#if $lastNotification}
  <ToastNotification
    bind:this={current}
    id={lastNotification.get().id}
    fullWidth
    class="z-10"
    title={lastNotification.get().type.toUpperCase()}
    subtitle={lastNotification.get().message}
    kind={lastNotification.get().type}
    on:close={(e) => {
      e.preventDefault()
      toast.dismiss(current.$$.ctx[12].id)
    }}
  />
{/if}
